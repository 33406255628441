import React from "react";
import { Typography, Box, Link } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ padding: 3, margin: 3 }}>
      <Box>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome, and thank you for your interest in Optexity (“Optexity”), and
          our website(s), products, services, and applications (the “Services”).
          This Privacy Policy is meant to help you understand what information
          we collect, why we collect it, and how you can update, manage, export,
          and delete your information. If you have any questions about this
          Privacy Policy, you can contact us{" "}
          <Link href="/contact-us">here</Link>.
        </Typography>
        <Typography variant="body1" paragraph>
          When you clicked “accept” or “agree” in connection with registering
          your account, we made available to you this Privacy Policy and the
          information handling practices described in here. This Privacy Policy
          only covers Optexity's practices regarding personal information, and
          not information more generally. We may use aggregated, anonymized data
          that we derived from your personal information before you deleted it,
          but not in a manner that incorporates any of your personal information
          or would identify you personally.
        </Typography>
        <Typography variant="body1" paragraph>
          We’ll ask for your consent before using your information for a purpose
          that isn’t covered in this Privacy Policy. You may, of course, decline
          to share certain personal information with Optexity, in which case
          Optexity may not be able to provide to you some of the features and
          functionality found on the Services.
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
