import { Box, Container } from "@mui/material";
import Content from "./Hero";
import HowItWorks from "./HowItWorks";
import Benefits from "./Benefits";
import Features from "./Features";

const Home = () => {
  return (
    <Box
      component="main"
      sx={{
        minHeight: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          py: { xs: 6, md: 8 },
          display: "flex",
          flexDirection: "column",
          gap: { xs: 6, md: 8 },
        }}
      >
        <Content />
        <Features />
        <HowItWorks />
        <Benefits />
      </Container>
    </Box>
  );
};

export default Home;
