import React from "react";
import { Box, Typography, Grid, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import SpeedIcon from "@mui/icons-material/Speed";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

const Features = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const featuresList = [
    {
      title: "AI-Powered Design",
      description: "Generate innovative designs using advanced AI algorithms",
      icon: <AutoFixHighIcon sx={{ fontSize: 48 }} />,
    },
    {
      title: "Real-time Optimization",
      description: "Instantly optimize designs for performance and efficiency",
      icon: <SpeedIcon sx={{ fontSize: 48 }} />,
    },
    {
      title: "Customizable Solutions",
      description: "Tailor designs to meet specific mobility requirements",
      icon: <SettingsSuggestIcon sx={{ fontSize: 48 }} />,
    },
  ];

  return (
    <Box
      id="features"
      component={motion.div}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
      sx={{
        py: isMobile ? 8 : 15,
        px: isMobile ? 3 : 10,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          fontWeight: 700,
          textAlign: "center",
          mb: 8,
          background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
      >
        Key Features
      </Typography>
      <Grid container spacing={6}>
        {featuresList.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Box
              component={motion.div}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
              sx={{
                textAlign: "center",
                p: 4,
                height: "100%",
                backgroundColor: theme.palette.background.default,
                borderRadius: 3,
                boxShadow: theme.shadows[1],
                transition: "all 0.3s ease",
                "&:hover": {
                  boxShadow: theme.shadows[3],
                },
              }}
            >
              <Box
                sx={{
                  mb: 3,
                  color: theme.palette.primary.main,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {feature.icon}
              </Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  mb: 2,
                  color: theme.palette.text.primary,
                }}
              >
                {feature.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.text.secondary,
                  lineHeight: 1.7,
                }}
              >
                {feature.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Features;
