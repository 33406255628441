import React, { useState } from "react";
import { AppBar, useMediaQuery } from "@mui/material";
import ContactModal from "./ContactModal";
import { useNavigate } from "react-router-dom";
import MobileNavbar from "./MobileNavbar";
import DesktopNavbar from "./DesktopNavbar";

const Navbar = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  React.useEffect(() => {
    const hash = window.location.hash.replace("#", "");
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [window.location.hash]);

  const handleContactUsClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const scrollToSection = (sectionId) => {
    if (window.location.pathname !== "/") {
      navigate(`/#${sectionId}`);
      return;
    }

    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "white" }}
      color="default"
      elevation={0}
    >
      {isMobile ? (
        <MobileNavbar
          scrollToSection={scrollToSection}
          handleContactUsClick={handleContactUsClick}
        />
      ) : (
        <DesktopNavbar
          scrollToSection={scrollToSection}
          handleContactUsClick={handleContactUsClick}
        />
      )}
      <ContactModal open={modalOpen} onClose={handleCloseModal} />
    </AppBar>
  );
};

export default Navbar;
