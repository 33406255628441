import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { UserProvider } from "./UserContext";
import Navbar from "./components/Navbar";
import { Container } from "@mui/material";
import Footer from "./components/Footer";
import About from "./components/About";
import Home from "./components/Home";
import Blogs from "./components/Blogs";
import BlogDetail from "./components/BlogDetail";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import ContactUs from "./components/ContactUs";
import RouteOpt from "./components/RouteOpt";
import Login from "./components/Login";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <UserProvider value={{ user, setUser }}>
      <Router>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Navbar />
          <Container style={{ flex: 1 }}>
            <Routes>
              <Route path="/about" exact element={<About />} />
              <Route path="/blogs" exact element={<Blogs />} />
              <Route path="/" exact element={<Home />} />
              <Route path="/blog/:id" exact element={<BlogDetail />} />
              <Route path="/terms-of-service" exact element={<Terms />} />
              <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
              <Route path="/contact-us" exact element={<ContactUs />} />
            </Routes>
          </Container>
          <Footer />
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
