import React from "react";
import { Box, Typography, Grid, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import TimerIcon from "@mui/icons-material/Timer";
import SavingsIcon from "@mui/icons-material/Savings";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import NatureIcon from "@mui/icons-material/Nature";

const Benefits = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const benefitsList = [
    {
      title: "Time Efficiency",
      description: "Reduce design time by up to 70% with AI-powered automation",
      icon: <TimerIcon sx={{ fontSize: 40 }} />,
      color: theme.palette.primary.dark,
    },
    {
      title: "Cost Reduction",
      description: "Lower development costs through optimized design processes",
      icon: <SavingsIcon sx={{ fontSize: 40 }} />,
      color: theme.palette.primary.dark,
    },
    {
      title: "Innovation",
      description:
        "Access cutting-edge design possibilities with AI assistance",
      icon: <LightbulbIcon sx={{ fontSize: 40 }} />,
      color: theme.palette.primary.dark,
    },
    {
      title: "Scalability",
      description: "Easily scale your designs from prototype to production",
      icon: <NatureIcon sx={{ fontSize: 40 }} />,
      color: theme.palette.primary.dark,
    },
  ];

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
      id="benefits"
      sx={{
        py: isMobile ? 8 : 15,
        px: isMobile ? 3 : 10,
        borderRadius: 4,
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          fontWeight: 700,
          textAlign: "center",
          mb: 8,
          background: (theme) =>
            `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.secondary.main} 90%)`,
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          color: "transparent",
        }}
      >
        Benefits
      </Typography>
      <Grid container spacing={6}>
        {benefitsList.map((benefit, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Box
              component={motion.div}
              whileHover={{ scale: 1.03 }}
              transition={{ type: "spring", stiffness: 200 }}
              sx={{
                p: 4,
                bgcolor: "background.paper",
                borderRadius: 3,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                overflow: "hidden",
                boxShadow: 1,
                "&:hover": {
                  boxShadow: 2,
                  "&::before": {
                    transform: "scale(1.1)",
                  },
                },
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "4px",
                  background: benefit.color,
                  transition: "transform 0.3s ease",
                },
              }}
            >
              <Box sx={{ mb: 2, color: benefit.color }}>{benefit.icon}</Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  mb: 2,
                  color: "text.primary",
                }}
              >
                {benefit.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "text.secondary",
                  lineHeight: 1.7,
                }}
              >
                {benefit.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Benefits;
