import React, { useState } from "react";
import LastFrameImage from "../assets/bg_content.png";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ContactModal from "./ContactModal";

const Content = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleContactUsClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        backgroundImage: `url(${LastFrameImage})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        pb: isMobile ? 4 : 8,
        px: isMobile ? 2 : 0,
      }}
    >
      <Typography
        variant={isMobile ? "h1_mobile" : "h1"}
        sx={{ fontWeight: 700, display: "block" }}
      >
        Generative AI for
      </Typography>
      <Typography
        variant={isMobile ? "h1_mobile" : "h1"}
        sx={{ fontWeight: 700, display: "block" }}
      >
        Mobility Design
      </Typography>
      <Typography
        variant={isMobile ? "h6" : "h4"}
        component="p"
        sx={{ color: "#55555D", pb: isMobile ? 2 : 5, pt: isMobile ? 1 : 2.5 }}
      >
        Harness the power of AI-driven generative design to create innovative
        and efficient mobility solutions
      </Typography>
      <Button
        onClick={handleContactUsClick}
        variant="contained"
        sx={{ color: "white", borderRadius: 6, width: "150px", height: "50px" }}
      >
        Contact Us
      </Button>
      <ContactModal open={modalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default Content;
