import React from "react";
import { Toolbar, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Optexity_logo from "../assets/logo_optexity.svg";

const DesktopNavbar = ({ scrollToSection, handleContactUsClick }) => {
  return (
    <Toolbar sx={{ justifyContent: "space-between", mx: 16, my: 2 }}>
      <Button component={Link} to="/" sx={{ padding: 0, minWidth: 0 }}>
        <Box
          component="img"
          alt="Optexity Logo"
          src={Optexity_logo}
          sx={{
            maxHeight: "48px",
            maxWidth: "192px",
          }}
        />
      </Button>

      <Box sx={{ gap: 2.5, alignItems: "center" }}>
        <Button
          onClick={() => scrollToSection("features")}
          sx={{ color: "black" }}
        >
          Features
        </Button>
        <Button
          onClick={() => scrollToSection("how-it-works")}
          sx={{ color: "black" }}
        >
          How It Works
        </Button>
        <Button
          onClick={() => scrollToSection("benefits")}
          sx={{ color: "black" }}
        >
          Benefits
        </Button>
        <Button component={Link} to="/blogs" sx={{ color: "black" }}>
          Blogs
        </Button>
        <Button component={Link} to="/about" sx={{ color: "black" }}>
          About Us
        </Button>
      </Box>
      <Box>
        <Button
          onClick={handleContactUsClick}
          variant="contained"
          sx={{ color: "white", borderRadius: 6 }}
        >
          Contact Us
        </Button>
      </Box>
    </Toolbar>
  );
};

export default DesktopNavbar;
