import React from "react";
import { Box, Typography, Stack, useMediaQuery, useTheme } from "@mui/material";

const HowItWorks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const steps = [
    {
      number: "01",
      title: "Input Requirements",
      description: "Define your mobility design requirements and constraints",
    },
    {
      number: "02",
      title: "AI Generation",
      description: "Our AI engine generates multiple design options",
    },
    {
      number: "03",
      title: "Refinement",
      description: "Fine-tune and optimize your selected design",
    },
  ];

  return (
    <Box
      id="how-it-works"
      sx={{
        py: isMobile ? 6 : 16,
        px: isMobile ? 2 : 10,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <Typography
        variant={isMobile ? "h3" : "h2"}
        sx={{
          fontWeight: 700,
          textAlign: "center",
          mb: isMobile ? 4 : 8,
          color: theme.palette.primary.dark,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            bottom: -16,
            left: "50%",
            transform: "translateX(-50%)",
            width: 60,
            height: 4,
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 2,
          },
        }}
      >
        How It Works
      </Typography>
      <Stack
        spacing={isMobile ? 3 : 6}
        sx={{
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        {steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "center" : "flex-start",
              gap: isMobile ? 2 : 4,
              padding: isMobile ? 2 : 3,
              textAlign: isMobile ? "center" : "left",
              backgroundColor: theme.palette.background.default,
              borderRadius: theme.shape.borderRadius,
              boxShadow: theme.shadows[1],
              transition: "transform 0.2s, box-shadow 0.2s",
              "&:hover": {
                transform: isMobile ? "none" : "translateY(-4px)",
                boxShadow: isMobile ? theme.shadows[1] : theme.shadows[4],
              },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                color: theme.palette.primary.light,
                opacity: 0.2,
                minWidth: isMobile ? "auto" : "80px",
                fontSize: isMobile ? "2.5rem" : "4rem",
                marginBottom: isMobile ? 1 : 0,
              }}
            >
              {step.number}
            </Typography>
            <Box sx={{ width: isMobile ? "100%" : "auto" }}>
              <Typography
                variant={isMobile ? "h6" : "h5"}
                sx={{
                  fontWeight: 600,
                  mb: 1,
                  color: theme.palette.primary.dark,
                }}
              >
                {step.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.text.secondary,
                  maxWidth: "600px",
                  fontSize: isMobile ? "0.9rem" : "1rem",
                }}
              >
                {step.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default HowItWorks;
