import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { Link } from "react-router-dom";
import EVImg from "../assets/ev_img.jpeg";

export const blogsData = [
  {
    id: "welcome-blog",
    title: "Decision Intelligence: The Unsung Hero of Data, Analytics, and AI",
    image: EVImg,
    author: "Shivam Goyal",
    date: "October 16, 2024",
    content: "",
  },
];

const Blogs = () => {
  return (
    <Container sx={{ py: 8 }}>
      <Typography
        variant="h2"
        gutterBottom
        sx={{
          fontWeight: 600,
          mb: 4,
          textAlign: "left",
          fontSize: { xs: "2.5rem", md: "3.5rem" },
        }}
      >
        Recent Blogs
      </Typography>
      <Grid container spacing={4}>
        {blogsData.map((blog, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-8px)",
                  boxShadow: (theme) => theme.shadows[8],
                },
              }}
            >
              <CardActionArea
                component={Link}
                to={`/blog/${blog.id}`}
                sx={{ flexGrow: 1 }}
              >
                <CardMedia
                  component="img"
                  height="240"
                  image={blog.image}
                  alt={blog.title}
                  sx={{
                    objectFit: "cover",
                  }}
                />
                <CardContent sx={{ p: 3 }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                      fontWeight: 600,
                      mb: 2,
                      lineHeight: 1.4,
                    }}
                  >
                    {blog.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      fontStyle: "italic",
                    }}
                  >
                    By {blog.author} • {blog.date}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Blogs;
