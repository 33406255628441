import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  IconButton,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AlokPatelImg from "../assets/alok_patel_img.jpeg";
import AnandSubImg from "../assets/anand_sub_img.jpeg";
import BhaveshImg from "../assets/bhavesh_img.jpeg";
import GoyalImg from "../assets/goyal_img.jpeg";
import BansalImg from "../assets/bansal_img.jpeg";

const teamMembers = [
  {
    name: "Bhavesh Tolia",
    role: "CEO",
    experiences: "IITD 2021",
    image: BhaveshImg,
    linkedin: "https://www.linkedin.com/in/bhavesh-tolia-a17a37164/",
  },
  {
    name: "Shivam Goyal",
    role: "CTO",
    experiences: "IIT Delhi 2021",
    image: GoyalImg,
    linkedin: "https://www.linkedin.com/in/shivam-goyal-9b844a17b/",
  },
  // {
  //   name: "Shivam Bansal",
  //   role: "Product and Strategy",
  //   experiences: "IITD 2021",
  //   image: BansalImg,
  //   linkedin: "https://www.linkedin.com/in/shivam-bansal99/",
  // },
  // {
  //   name: "Alok Patel",
  //   role: "Mentor",
  //   experiences: "8 years in financial management.",
  //   image: AlokPatelImg,
  //   linkedin: "https://www.linkedin.com/in/alok7iitb/",
  // },
  {
    name: "Anand Subramanian",
    role: "Advisor",
    experiences: "7 years in marketing and brand management.",
    image: AnandSubImg,
    linkedin: "https://www.linkedin.com/in/anandsubraman/",
  },
];

const About = () => {
  return (
    <Container>
      <Box sx={{ maxWidth: 800, my: 5 }}>
        <Typography variant="h2" gutterBottom sx={{ pb: 3 }}>
          Revolutionizing Mobility Design Through Generative AI
        </Typography>
        <Typography variant="h5" sx={{ pb: 2, color: "#55555D" }}>
          We're pioneering the intersection of Generative AI and mobility
          design, transforming how urban spaces and transportation systems are
          conceived and optimized. Our cutting-edge technology empowers
          designers and urban planners to create more efficient, adaptive,
          and human-centric mobility solutions.
        </Typography>
        <Typography variant="h5" sx={{ pb: 2, color: "#55555D" }}>
          By leveraging advanced machine learning algorithms, we analyze
          countless variables - from traffic patterns to pedestrian behavior -
          to generate optimal layout designs that enhance mobility flow while
          prioritizing safety and accessibility. Our AI-driven approach
          considers multiple scenarios and constraints, delivering solutions
          that adapt to real-world challenges.
        </Typography>
        <Typography variant="h5" sx={{ pb: 2, color: "#55555D" }}>
          Join us in reshaping the future of urban mobility, where data-driven
          insights meet creative design to build smarter, more connected cities
          for everyone.
        </Typography>
      </Box>

      <Box my={6}>
        <Typography variant="h2" component="h2" gutterBottom>
          Our Team
        </Typography>
        <Grid container spacing={4}>
          {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  maxWidth: 345,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="img"
                  image={member.image}
                  alt={member.name}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="h3">
                      {member.name}
                    </Typography>
                    <IconButton
                      component="a"
                      href={member.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ ml: 1 }}
                    >
                      <LinkedInIcon />
                    </IconButton>
                  </Box>
                  <Typography variant="subtitle1" color="textSecondary">
                    {member.role}
                  </Typography>
                  {/* <Typography variant="body2" color="textSecondary">
                    {member.experiences}
                  </Typography> */}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default About;
