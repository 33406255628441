import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL ?? "http://localhost:8000",
  headers: {
    "Content-Type": "application/json",
  },
});

export const sendEmail = async ({ email, name, phone, message }) => {
  try {
    await apiClient.post("/send-email/", {
      to_address: email,
      phone: phone,
      name: name,
      message: message,
    });
  } catch (error) {
    console.error("Error sending email", error);
  }
};
