import React, { useState } from "react";
import {
  Toolbar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import Optexity_logo from "../assets/logo_optexity.svg";

const MobileNavbar = ({ scrollToSection, handleContactUsClick }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <Toolbar sx={{ justifyContent: "space-between", mx: 2, my: 2 }}>
      <Button component={Link} to="/" sx={{ padding: 0, minWidth: 0 }}>
        <Box
          component="img"
          alt="Optexity Logo"
          src={Optexity_logo}
          sx={{
            maxHeight: "48px",
            maxWidth: "150px",
          }}
        />
      </Button>

      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleMenuOpen}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            scrollToSection("features");
            handleMenuClose();
          }}
        >
          Features
        </MenuItem>
        <MenuItem
          onClick={() => {
            scrollToSection("how-it-works");
            handleMenuClose();
          }}
        >
          How It Works
        </MenuItem>
        <MenuItem
          onClick={() => {
            scrollToSection("benefits");
            handleMenuClose();
          }}
        >
          Benefits
        </MenuItem>
        <MenuItem component={Link} to="/blogs" onClick={handleMenuClose}>
          Blogs
        </MenuItem>
        <MenuItem component={Link} to="/about" onClick={handleMenuClose}>
          About Us
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleContactUsClick();
            handleMenuClose();
          }}
        >
          Contact Us
        </MenuItem>
      </Menu>
    </Toolbar>
  );
};

export default MobileNavbar;
