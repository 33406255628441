import React from "react";
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
  Box,
} from "@mui/material";
import theme from "../theme";
import BlogPostImg from "../assets/BlogPostImg.png";

const BlogContent = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container>
      <Paper
        elevation={0}
        sx={{ paddingX: isMobile ? "0" : "120px", marginTop: "20px" }}
      >
        <Typography variant="body1" gutterBottom>
          <strong>Disclaimer:</strong> If you're an urban planner, architect, or
          involved in city planning, this blog might be very relevant for you!
        </Typography>
        <Divider sx={{ marginY: 2 }} />

        <Typography variant="body1" paragraph sx={{ marginTop: 5 }}>
          Hello and Welcome !
        </Typography>

        <Typography variant="body1" paragraph>
          This is the first blog in our series, where we explore how generative
          AI is revolutionizing the way we design and optimize mobility layouts
          in urban spaces. While traditional urban planning has relied heavily
          on human expertise and manual design processes, AI is opening up new
          possibilities that were previously unimaginable.
        </Typography>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 3 }}
        >
          Generative AI in Mobility Design
        </Typography>

        <Typography variant="body1" paragraph>
          Urban mobility is becoming increasingly complex. Cities are growing,
          transportation modes are diversifying, and sustainability requirements
          are becoming stricter. Generative AI offers a powerful solution by
          analyzing vast amounts of data and generating optimal layout designs
          that consider multiple factors simultaneously.
        </Typography>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 3 }}
        >
          Key Components of AI-Driven Layout Design
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="A. Historical mobility data and usage patterns" />
          </ListItem>
          <ListItem>
            <ListItemText primary="B. Environmental and infrastructure constraints" />
          </ListItem>
          <ListItem>
            <ListItemText primary="C. Future growth projections and sustainability goals" />
          </ListItem>
        </List>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 4 }}
        >
          The Three Stages of AI-Powered Design
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Layout Analysis"
              secondary={
                <Typography fontSize="1rem">
                  AI analyzes existing mobility patterns and identifies
                  bottlenecks and inefficiencies in current layouts.
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Design Generation"
              secondary={
                <Typography fontSize="1rem">
                  Multiple layout options are generated considering various
                  constraints and optimization goals.
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Performance Simulation"
              secondary={
                <Typography fontSize="1rem">
                  AI simulates and predicts the performance of generated layouts
                  under different scenarios.
                </Typography>
              }
            />
          </ListItem>
        </List>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 2 }}
        >
          The Technology
        </Typography>

        <Typography paragraph>
          With more and more data, the world is becoming increasingly complex
          each passing year. So the process of decision making has become more
          complex owing to dealing with more variables. And that calls for
          bringing in technology to help your business make informative and
          intelligent decisions. Sometimes (mostly) decision making problems can
          be hard, in fact so hard that they are NP-Hard, meaning no efficient
          solution is known even for computers. Fortunately, we have some
          friends to help tackle these complexities. Several fields contribute
          to the study of Decision Intelligence, including mathematical
          optimization, operations research, complexity theory, heuristics, and
          AI. Ever heard of the Travelling Salesman Problem (TSP), ask any
          competitive programmer and you will hear it cannot be solved for more
          than 20 locations. But these technologies use it as a toy problem,
          routinely solving TSP optimally for 1000 locations. Decision
          Intelligence is a very powerful technology with a lot of literature on
          the complex problems businesses around the world face today in their
          operations.
        </Typography>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 4 }}
        >
          What are Some Business Applications of DI
        </Typography>

        <TableContainer component={Paper} sx={{ marginY: 2 }}>
          <Table aria-label="decision intelligence use cases">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Use Case</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Description</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Example</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight="bold">
                    Traffic Flow Optimization
                  </Typography>
                </TableCell>
                <TableCell>
                  Generating optimal road layouts and signal timing for smooth
                  traffic flow.
                </TableCell>
                <TableCell>Smart intersection design</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight="bold">
                    Multi-modal Integration
                  </Typography>
                </TableCell>
                <TableCell>
                  Designing seamless connections between different
                  transportation modes.
                </TableCell>
                <TableCell>Transit hub layout optimization</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight="bold">
                    Server Allocation
                  </Typography>
                </TableCell>
                <TableCell>
                  Allocating computing resources to tasks to maximize
                  performance and minimize costs.
                </TableCell>
                <TableCell>AWS cost optimization</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight="bold">
                    Portfolio Optimization
                  </Typography>
                </TableCell>
                <TableCell>
                  Selecting the best mix of assets to maximize returns while
                  managing risk.
                </TableCell>
                <TableCell>
                  Trading strategies in different market segments
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="body1" gutterBottom sx={{ marginTop: 4 }}>
          <strong>Answers to all your how/which/what:</strong>
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Resource Allocation"
              secondary={
                <Typography fontSize="1rem">
                  How many groundwork people to hire in which city, how much
                  raise to give to which employee, how much loan to give to
                  which people.
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Optimal Matching"
              secondary={
                <Typography fontSize="1rem">
                  Which ad to show to which user, which dating profile to show
                  to which person, which ride request to show to which driver.
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Strategy Making"
              secondary={
                <Typography fontSize="1rem">
                  What is the best online marketing strategy, what is the best
                  hiring strategy, what is the best dynamic pricing strategy.
                </Typography>
              }
            />
          </ListItem>
        </List>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 2 }}
        >
          Present and Future Potential
        </Typography>

        <Typography paragraph>
          The quality of decision making at scale is poised to become the
          differentiator of businesses within the same industry. For instance,
          take Blinkit’s vs Zepto’s approach for decisions at all scales in
          quick commerce. From low frequency decisions like dark store placement
          to mid frequency like inventory management to high frequency like
          delivery executive assignment, their strategic decisions ultimately
          guide their service levels.
        </Typography>
        <Typography paragraph>
          So basically DI is everywhere in some form or the other. Then why do
          businesses shy away from having decision intelligence teams? The
          reason is simple, DI has often been seen as a <strong>vitamin</strong>
          , "nice to have but not essential". However, with increasing scale,
          competition, and complexity, optimization is set to become a{" "}
          <strong>painkiller</strong>, "vital for survival and growth".
          Businesses will find it impossible to operate efficiently without it,
          as DI will be crucial in saving time, reducing costs, and increasing
          sales. As we move forward, the adoption of Decision Intelligence will
          likely become talk of the business, transforming it from an optional
          advantage into a must-have tool for success.
        </Typography>

        <Box mt={4}>
          <img
            src={BlogPostImg}
            alt={"Linkedin Content on Decision Intelligence"}
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Typography variant="body2">
          <strong>
            Fig 1. What industry experts have to say about mathematical
            optimization, decision intelligence, AI and operations research
          </strong>
        </Typography>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 4 }}
        >
          Some good news
        </Typography>

        <Typography paragraph>
          Decision Intelligence is currently being used at a mature stage in a
          few industries namely, Logistics, Supply Chain, Airlines, Energy and
          Manufacturing. But the scope as we can see, is immense with
          innumerable applications for today and the future.
        </Typography>

        <Typography paragraph>
          Join us on this journey to make Decision Intelligence accessible for a
          more efficient world with smarter decisions. Consider this your sign
          to integrate optimization into your business to gain a competitive
          edge. Reach out to us to discover how Decision Intelligence can
          enhance your operations. Stay tuned as we get started and kick things
          off!
        </Typography>
      </Paper>
    </Container>
  );
};

export default BlogContent;
