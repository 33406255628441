import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";

const Terms = () => {
  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h4" gutterBottom>
          Terms of Use
        </Typography>
        <Typography variant="body1" paragraph>
          PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED
          BELOW) ARE SUBJECT TO THE FOLLOWING TERMS. IF YOU DO NOT AGREE TO ALL
          OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY
          MANNER.
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Optexity. Please read on to learn the rules and
          restrictions that govern your use of our website(s), products,
          services, and applications (the “Services”). If you have any
          questions, comments, or concerns regarding these Terms or the
          Services, please contact us. These Terms of Use (the “Terms”) are a
          binding contract between you and Optexity Inc. (“Optexity,” “we,” and
          “us”). Optexity is organized under the laws of Haryana, India, and has
          offices located at Booth No. 697, Motor Market, Manimajra, Sector 13,
          Chandigarh 160101.
        </Typography>
        <Typography variant="body1" paragraph>
          You must agree to and accept all of the Terms, or you don’t have the
          right to use the Services. Your use of the Services in any way means
          that you agree to all of these Terms, and these Terms will remain in
          effect while you use the Services. These Terms include the provisions
          in this document, as well as those in the Privacy Policy, Copyright
          Dispute Policy, and the Community Guidelines. In these Terms, the
          words “include” or “including” mean “including but not limited to”,
          and examples are for illustration purposes and are not limiting.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Will these Terms ever change?
        </Typography>
        <Typography variant="body1" paragraph>
          We are constantly trying to improve our Services, so these Terms may
          need to change along with the Services. We reserve the right to change
          the Terms at any time, but if we do, we will bring it to your
          attention by placing a notice on the www.optexity.in website, by
          sending you an email, or by some other means. Changes will not apply
          retroactively and will become effective no sooner than 14 days after
          they are posted. However, changes addressing new functions for a
          Service or changes made for legal reasons will be effective
          immediately. If you don’t agree with the new Terms, you are free to
          reject them; unfortunately, that means you will no longer be able to
          use the Services. If you use the Services in any way after a change to
          the Terms is effective, that means you agree to all of the changes.
        </Typography>
        <Typography variant="body1" paragraph>
          Except for changes by us as described here, no other amendment or
          modification of these Terms will be effective unless in writing and
          signed by both you and us.
        </Typography>

        <Typography variant="h6" gutterBottom>
          What about my privacy?
        </Typography>
        <Typography variant="body1" paragraph>
          Optexity takes the privacy of its users very seriously. For the
          current Optexity Privacy Policy, please{" "}
          <Link href="https://www.optexity.in/privacy" target="_blank">
            click here
          </Link>
          .
        </Typography>

        <Typography variant="h6" gutterBottom>
          What are the basics of using Optexity?
        </Typography>
        <Typography variant="body1" paragraph>
          You may be required to sign up for an account, and select a password
          and username (“Optexity User ID”). You promise to provide us with
          accurate, complete, and updated registration information about
          yourself. You may not select as your Optexity User ID a name that you
          don’t have the right to use, or another person’s name with the intent
          to impersonate that person. You may not transfer your account to
          anyone else without our prior written permission. You also may not
          have, control, or operate under more than one active Optexity account
          or Optexity User ID. If we determine that you have, control, or are
          operating under more than one Optexity account or Optexity User ID, we
          may take action without notice, including banning your user account,
          revoking access to your Optexity User ID, and disqualifying you from
          any ongoing Competition(s).
        </Typography>
        <Typography variant="body1" paragraph>
          If you are not of legal age to form a binding contract, please see
          “Our Commitment to Children’s Privacy” in the Privacy Policy and
          contact us at Optexity Support for more information on the parental
          consent process.
        </Typography>
        <Typography variant="body1" paragraph>
          If you’re agreeing to these Terms on behalf of an organization or
          entity, you represent and warrant that you are authorized to agree to
          these Terms on that organization or entity’s behalf and bind them to
          these Terms (in which case, the references to “you” and “your” in
          these Terms, except for in this sentence, refer to that organization
          or entity), but you are still required to sign up each individual user
          from your organization with a Optexity User ID. You are not allowed to
          allow multiple individuals to operate under one Optexity User ID, even
          if you are an organization or entity.
        </Typography>
        <Typography variant="body1" paragraph>
          You will only use the Services for your own internal, personal,
          non-commercial use, and not on behalf of or for the benefit of any
          third party, and only in a manner that complies with all laws that
          apply to you. If your use of the Services is prohibited by applicable
          laws, then you aren’t authorized to use the Services. We are not
          responsible if you use the Services in a way that breaks the law.
        </Typography>
        <Typography variant="body1" paragraph>
          You will keep all your registration information accurate and current.
          You will not share your account or password with anyone, and you must
          protect the security of your account and your password. You’re
          responsible for any activity associated with your account.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Are there any additional restrictions on my use of the Services?
        </Typography>
        <Typography variant="body1" paragraph>
          Yes. Unless otherwise specified in writing by Optexity, Optexity does
          not intend uses of the Services to create obligations under the Health
          Insurance Portability and Accountability Act of 1996 as it may be
          amended from time to time, and any regulations issued under it
          (“HIPAA”), and makes no representations that the Services satisfy
          HIPAA requirements. If you are or become a Covered Entity or Business
          Associate, as defined in HIPAA, you will not use the Services for any
          purpose or in any manner involving Protected Health Information (as
          defined in HIPAA) unless you have received prior written consent for
          such use from Optexity.
        </Typography>
        <Typography variant="body1" paragraph>
          You represent, warrant, and agree that you will not contribute any
          Content or User Submission or otherwise use the Services or interact
          with the Services in a manner that:
        </Typography>
        <ul>
          <Typography component="li" variant="body1">
            Violates the Acceptable Use Policy, available at
            www.optexity.in/aup;
          </Typography>
          <Typography component="li" variant="body1">
            Is harmful, threatening, harassing, defamatory, obscene, or
            otherwise objectionable;
          </Typography>
          <Typography component="li" variant="body1">
            “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or
            relating to the Services or Content (through use of manual or
            automated means);
          </Typography>
          <Typography component="li" variant="body1">
            Copies or stores any significant portion of the Content;
          </Typography>
          <Typography component="li" variant="body1">
            Decompiles, reverse engineers, or otherwise attempts to obtain the
            source code or underlying ideas or information of or relating to the
            Services;
          </Typography>
          <Typography component="li" variant="body1">
            Processes or stores any data that is subject to the International
            Traffic in Arms Regulations maintained by the U.S. Department of
            State;
          </Typography>
          <Typography component="li" variant="body1">
            Engages in cryptomining or otherwise encourages, requests, or allows
            any form of cryptomining.
          </Typography>
        </ul>
        <Typography variant="body1" paragraph>
          Without limitation to any other remedies available to Optexity, a
          violation of any of the foregoing is grounds for termination of your
          right to use or access the Services. We reserve the right to remove
          any Content or User Submissions from the Services at any time, for any
          reason (including if someone alleges you contributed that Content in
          violation of these Terms), and without notice.
        </Typography>

        <Typography variant="h6" gutterBottom>
          What are my rights in Optexity?
        </Typography>
        <Typography variant="body1" paragraph>
          The materials displayed or performed or available on or through the
          Services, including text, graphics, data, articles, photos, images,
          illustrations, and User Submissions (collectively, the “Content”), are
          protected by copyright and other intellectual property laws. You
          promise to abide by all copyright notices, trademark rules,
          information, and restrictions contained in any Content you access
          through the Services, and you won’t use, copy, reproduce, modify,
          translate, publish, broadcast, transmit, distribute, perform, upload,
          display, license, sell or otherwise exploit for any purpose any
          Content not owned by you, (i) without the prior consent of the owner
          of that Content or (ii) in a way that violates someone else’s
          (including Optexity’s) rights.
        </Typography>
        <Typography variant="body1" paragraph>
          You understand that Optexity owns the Services. You won’t modify,
          publish, transmit, participate in the transfer or sale of, reproduce
          (except as expressly provided in this Section), create derivative
          works based on, or otherwise exploit any of the Services.
        </Typography>
        <Typography variant="body1" paragraph>
          The Services may allow you to copy or download certain Content; please
          remember that just because this functionality exists, doesn’t mean
          that all the restrictions above don’t apply — they do!
        </Typography>
        <Typography variant="body1" paragraph>
          A notice for U.S. Federal Agency Users: The Services were developed
          solely at private expense and are commercial computer software and
          related documentation within the meaning of the applicable Federal
          Acquisition Regulations and their agency supplements.
        </Typography>
        <Typography variant="body1" paragraph>
          If you’re an EEA-based consumer of digital content or services and
          you’ve agreed to these Terms, then EEA consumer laws provide you with
          a legal guarantee. Under this guarantee, we’re liable for any lack of
          conformity that you discover:
        </Typography>
        <ul>
          <Typography component="li" variant="body1">
            within two years of the one-time supply of digital content or
            services; or
          </Typography>
          <Typography component="li" variant="body1">
            at any time during the “continuous” supply of digital content or
            services.
          </Typography>
        </ul>
        <Typography variant="body1" paragraph>
          Your national laws may provide an even longer guarantee. Your rights
          under these legal guarantees aren’t limited by any other commercial
          guarantees that we provide. If you want to make a guarantee claim,
          please contact customer support at{" "}
          <Link href="mailto:support@optexity.in">support@optexity.in</Link>.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Who is responsible for what I see and do on the Services?
        </Typography>
        <Typography variant="body1" paragraph>
          Any information or content publicly posted or privately transmitted
          through the Services is the sole responsibility of the person from
          whom such content originated, and you access all such information and
          content at your own risk. We aren’t liable for any errors or omissions
          in that information or content or for any damages or loss you might
          suffer in connection with it. We cannot control and have no duty to
          take any action regarding how you may interpret and use the Content or
          what actions you may take as a result of having been exposed to the
          Content, and you release us from all liability for you having acquired
          or not acquired Content through the Services. We can’t guarantee the
          identity of any users with whom you interact in using the Services and
          are not responsible for which users gain access to the Services.
        </Typography>
        <Typography variant="body1" paragraph>
          You are responsible for all Content you contribute to the Services,
          and you represent and warrant you have all rights necessary to do so.
        </Typography>
        <Typography variant="body1" paragraph>
          The Services may contain links, information, or connections to
          third-party websites or services that are not owned or controlled by
          Optexity. When you access third-party websites or engage with
          third-party services, you accept that there are risks in doing so, and
          that Optexity is not responsible for such risks. We encourage you to
          be aware when you leave the Services and to read the terms and privacy
          policy of each third-party website or service that you visit or
          utilize.
        </Typography>
        <Typography variant="body1" paragraph>
          Optexity has no control over, and assumes no responsibility for, the
          content, accuracy, privacy policies, or practices of or opinions
          expressed in any third-party websites or by any third party that you
          interact with through the Services. In addition, Optexity will not and
          cannot monitor, verify, censor, or edit the content of any third-party
          site or service. By using the Services, you release and hold us
          harmless from any and all liability arising from your use of any
          third-party website or service.
        </Typography>
        <Typography variant="body1" paragraph>
          Your interactions with organizations or individuals found on or
          through the Services, including payment and delivery of goods or
          services, and any other terms, conditions, warranties or
          representations associated with such dealings, are solely between you
          and such organizations or individuals. You should make whatever
          investigation you feel necessary or appropriate before proceeding with
          any online or offline transaction with any of these third parties. You
          agree that optexity will not be responsible or liable for any loss or
          damage of any sort incurred as the result of any such dealings.\ If
          there is a dispute between participants on this site, or between users
          and any third party, you agree that optexity is under no obligation to
          become involved. If you have a dispute with one or more other users,
          you release optexity, its officers, employees, agents, and successors
          from claims, demands, and damages of every kind or nature, known or
          unknown, suspected or unsuspected, disclosed or undisclosed, arising
          out of or in any way related to such disputes or our Services. If you
          are a California resident, you are expressly waiving California Civil
          Code Section 1542, which says: "A general release does not extend to
          claims which the creditor does not know or suspect to exist in his or
          her favor at the time of executing the release, which, if known by him
          or her must have materially affected his or her settlement with the
          debtor."
        </Typography>
        <Typography variant="body1" paragraph>
          The laws of Chandigarh, India, will apply to any disputes arising out
          of or relating to these Terms or the Services. All claims arising out
          of or relating to these Terms or the Services will be litigated
          exclusively in the federal or state courts of Chandigarh, India, and
          you and optexity consent to personal jurisdiction in those courts.
        </Typography>
      </Box>
    </Container>
  );
};

export default Terms;
