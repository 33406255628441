import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
  Autocomplete,
  TextField,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import DeleteIcon from "@mui/icons-material/Delete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import RouteIcon from "@mui/icons-material/Route";
import OptimizeIcon from "@mui/icons-material/TrendingUp";
import DownloadIcon from "@mui/icons-material/Download";
import ChatIcon from "@mui/icons-material/Chat";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import Papa from "papaparse";
import axios from "axios";
import Chatbot from "./Chatbot";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ?? "https://codescore.net";

const RouteOpt = () => {
  const [mapCenter, setMapCenter] = useState({ lat: 40.7685, lng: -73.9822 });
  const [stops, setStops] = useState([]);
  const [dirStops, setDirStops] = useState([]);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [directions, setDirections] = useState(null);
  const [optimizedPlan, setOptimizedPlan] = useState(null);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isOptimizing, setIsOptimizing] = useState(false);
  const mapRef = useRef();
  const [libraries] = useState(["places"]);
  const [calculatedRouteInfo, setCalculatedRouteInfo] = useState(null); // New state for calculated route info
  const [optimizedRouteInfo, setOptimizedRouteInfo] = useState(null); // New state for optimized route info
  const [bounds, setBounds] = useState(null);
  const [nVisits, setNVisits] = useState("");
  const [maximumDistance, setMaximumDistance] = useState("300");
  const [distanceMatrix, setDistanceMatrix] = useState(null);
  const calculatedRouteRef = useRef(null);
  const optimizedRouteRef = useRef(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCxRWCempNoZ2TnKJVvS_Bnz-Nm4JLdlkc",
    libraries,
  });

  const handlePlaceSelect = (place, type) => {
    if (place && place.place_id) {
      const service = new window.google.maps.places.PlacesService(
        mapRef.current
      );
      service.getDetails(
        {
          placeId: place.place_id,
          fields: ["geometry", "formatted_address"],
        },
        (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const newPlace = {
              address: result.formatted_address,
              lat: result.geometry.location.toJSON().lat,
              lng: result.geometry.location.toJSON().lng,
              location: result.geometry.location.toJSON(),
            };

            if (type === "start") {
              setStartPoint(newPlace);
            } else if (type === "end") {
              setEndPoint(newPlace);
            } else {
              setStops((prev) => [...prev, newPlace]);
            }

            setMapCenter(newPlace.location);
          }
        }
      );
    }
  };

  const handleDeleteStop = (index) => {
    setStops((prev) => prev.filter((_, i) => i !== index));
  };

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const calculateRoute = useCallback(
    async (routeStops = stops, isOptimizingRoute = false) => {
      if (
        !startPoint?.location ||
        !endPoint?.location ||
        routeStops.length === 0
      ) {
        alert(
          "Please set start point, end point, and at least one stop before calculating the route."
        );
        return { totalDistance: 0, totalDuration: 0 };
      }

      try {
        if (!isOptimizingRoute) {
          setIsCalculating(true);
        }
        const response = await axios.post(`${API_BASE_URL}/calculate-route`, {
          start_point: startPoint,
          end_point: endPoint,
          stops: routeStops,
          n_visits:
            nVisits === "" ? stops.length + 2 : parseInt(nVisits, 10) + 2,
          maximum_distance:
            maximumDistance === ""
              ? 300 * 1000
              : parseFloat(maximumDistance) * 1000,
        });

        const { totalDistance, totalDuration } = response.data;
        setDirStops(routeStops);

        const directionsService = new window.google.maps.DirectionsService();
        const waypoints = routeStops.map((stop) => ({
          location: new window.google.maps.LatLng(
            stop.location.lat,
            stop.location.lng
          ),
          stopover: true,
        }));

        directionsService.route(
          {
            origin: new window.google.maps.LatLng(
              startPoint.location.lat,
              startPoint.location.lng
            ),
            destination: new window.google.maps.LatLng(
              endPoint.location.lat,
              endPoint.location.lng
            ),
            waypoints: waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
              if (!isOptimizingRoute) {
                setCalculatedRouteInfo(
                  <>
                    <Typography sx={{ fontSize: "14px" }}>
                      Total Distance: {totalDistance.toFixed(2)} km
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      Total Duration: {totalDuration.toFixed(2)} minutes
                    </Typography>
                  </>
                );
                setTimeout(() => scrollToSection(calculatedRouteRef), 100);
              } else {
                setOptimizedRouteInfo(
                  <>
                    <Typography sx={{ fontSize: "14px" }}>
                      Total Distance: {totalDistance.toFixed(2)} km
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      Total Duration: {totalDuration.toFixed(2)} minutes
                    </Typography>
                  </>
                );
                setTimeout(() => scrollToSection(optimizedRouteRef), 100);
              }
            } else {
              console.error("Directions request failed due to " + status);
              alert("Failed to calculate route. Please try again.");
            }
          }
        );

        return { totalDistance, totalDuration };
      } catch (error) {
        console.error("Error calculating route:", error);
        if (error.response) {
          console.error("Error response from server:", error.response.data);
        }
        alert(
          "An error occurred while calculating the route. Please try again."
        );
        return { totalDistance: 0, totalDuration: 0 };
      } finally {
        if (!isOptimizingRoute) {
          setIsCalculating(false);
        }
      }
    },
    [startPoint, endPoint, stops, maximumDistance, nVisits]
  );

  const optimizeRoute = async () => {
    setIsOptimizing(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/optimize-route`, {
        start_point: startPoint,
        end_point: endPoint,
        stops: stops,
        n_visits: nVisits === "" ? stops.length + 2 : parseInt(nVisits, 10) + 2,
        maximum_distance:
          maximumDistance === ""
            ? 300 * 1000
            : parseFloat(maximumDistance) * 1000,
      });
      const { route, distance_matrix } = response.data;
      setDistanceMatrix(distance_matrix);

      const newStops = route.slice(1, -1); // Exclude start and end points
      await calculateRoute(newStops, true);
      setOptimizedPlan(route);
    } catch (error) {
      console.error("Error optimizing route:", error);
      alert("An error occurred while optimizing the route. Please try again.");
    } finally {
      setIsOptimizing(false);
    }
  };

  const downloadOptimizedPlan = () => {
    if (!optimizedPlan) {
      alert("Please optimize the route first.");
      return;
    }

    const planText = optimizedPlan
      .map((location, index) => {
        if (index === 0) return `Start: ${location.address}`;
        if (index === optimizedPlan.length - 1)
          return `End: ${location.address}`;
        return `Stop ${index}: ${location.address}`;
      })
      .join("\n");

    const blob = new Blob([planText], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "optimized_route_plan.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      if (fileType === "csv") {
        Papa.parse(file, {
          complete: (results) => {
            if (results.errors.length > 0) {
              console.error("CSV parsing errors:", results.errors);
              alert(
                "There were errors parsing the CSV file. Please check the console for details."
              );
              return;
            }
            const newStops = results.data
              .filter((row) => {
                if (row.length < 2 || !row[0] || !row[1]) {
                  console.warn("Skipping invalid row:", row);
                  return false;
                }
                return true;
              })
              .map((row) => {
                const lat = parseFloat(row[0]);
                const lng = parseFloat(row[1]);
                if (isNaN(lat) || isNaN(lng)) {
                  console.warn("Invalid coordinates:", row);
                  return null;
                }
                return {
                  address: row[2]
                    ? `${row[2]} (${lat}, ${lng})`
                    : `(${lat}, ${lng})`,
                  lat: lat,
                  lng: lng,
                  location: { lat: lat, lng: lng },
                };
              })
              .filter(Boolean);
            updateStopsAndFitBounds(newStops);
          },
          header: false,
          skipEmptyLines: true,
          error: (error) => {
            console.error("CSV parsing error:", error);
            alert(
              "Error parsing CSV file. Please check the console for details."
            );
          },
        });
      } else if (fileType === "txt") {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const text = e.target.result;
          const addresses = text
            .split("\n")
            .filter((line) => line.trim() !== "");
          try {
            const newStops = await Promise.all(
              addresses.map(async (address) => {
                try {
                  const result = await geocodeAddress(address);
                  return {
                    address: result.formatted_address,
                    lat: result.geometry.location.lat(),
                    lng: result.geometry.location.lng(),
                    location: {
                      lat: result.geometry.location.lat(),
                      lng: result.geometry.location.lng(),
                    },
                  };
                } catch (error) {
                  console.error(`Error geocoding address: ${address}`, error);
                  return null;
                }
              })
            );
            const validStops = newStops.filter(Boolean);
            updateStopsAndFitBounds(validStops);
          } catch (error) {
            console.error("Error processing TXT file:", error);
            alert(
              "Error processing TXT file. Please check the console for details."
            );
          }
        };
        reader.onerror = (error) => {
          console.error("Error reading TXT file:", error);
          alert("Error reading TXT file. Please try again.");
        };
        reader.readAsText(file);
      } else {
        console.error("Unsupported file type:", fileType);
        alert("Unsupported file type. Please upload a .csv or .txt file.");
      }
    } else {
      console.warn("No file selected");
    }
  };

  const updateStopsAndFitBounds = (newStops) => {
    setStops((prev) => {
      const updatedStops = [...prev, ...newStops];

      // Calculate new bounds
      const newBounds = new window.google.maps.LatLngBounds();
      updatedStops.forEach((stop) => {
        newBounds.extend(
          new window.google.maps.LatLng(stop.location.lat, stop.location.lng)
        );
      });
      setBounds(newBounds);

      return updatedStops;
    });
  };

  const geocodeAddress = (address) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK" && results[0]) {
          resolve(results[0]);
        } else {
          reject(
            new Error(
              `Geocoding failed for address: ${address}. Status: ${status}`
            )
          );
        }
      });
    });
  };

  const LocationAutocomplete = ({ onSelect, placeholder, type, value }) => {
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);

    useEffect(() => {
      if (typeof window !== "undefined" && !loaded.current && window.google) {
        loaded.current = true;
      }
    }, []);

    const fetch = useCallback(
      throttle((input, callback) => {
        const service = new window.google.maps.places.AutocompleteService();
        service.getPlacePredictions({ input }, callback);
      }, 200),
      []
    );

    useEffect(() => {
      let active = true;

      if (!loaded.current) {
        return undefined;
      }

      if (inputValue === "") {
        setOptions(value ? [value] : []);
        return undefined;
      }

      fetch(inputValue, (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });

      return () => {
        active = false;
      };
    }, [value, inputValue, fetch]);

    return (
      <Autocomplete
        freeSolo
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : option.description || option.address
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          onSelect(newValue, type);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            fullWidth
            InputLabelProps={{
              shrink: true, // Keeps the label aligned
            }}
            InputProps={{
              ...params.InputProps,
              sx: { height: "100%" }, // Ensures full height for the input
            }}
          />
        )}
        sx={{
          "& .MuiInputBase-root": {
            height: 40, // Change the height of the input
          },
          "& .MuiInputBase-input": {
            fontSize: "0.9rem", // Reduce font size of the input text
          },
          "& .MuiAutocomplete-option": {
            fontSize: "0.9rem", // Reduce font size of the dropdown options
          },
          "& .MuiAutocomplete-listbox": {
            maxHeight: 200, // Change the height of the dropdown
          },
        }}
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting?.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting?.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={LocationOnIcon}
                    sx={{ color: "text.secondary", mr: 2 }}
                  />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting?.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    );
  };

  const toggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };

  useEffect(() => {
    setNVisits(stops.length.toString());
  }, [stops]);

  const handleNVisitsChange = (event) => {
    const value = event.target.value;
    if (
      value === "" ||
      (parseInt(value, 10) >= 0 && parseInt(value, 10) <= stops.length)
    ) {
      setNVisits(value);
    }
  };

  const handleMaximumDistanceChange = (event) => {
    const value = event.target.value;
    if (value === "" || !isNaN(parseFloat(value))) {
      setMaximumDistance(value);
    }
  };

  // Use effect to fit bounds when they change
  useEffect(() => {
    if (bounds && mapRef.current) {
      mapRef.current.fitBounds(bounds);
    }
  }, [bounds]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        height: "100vh",
        width: "100vw",
      }}
    >
      {/* Map Container - Always visible on mobile */}
      <Box
        sx={{
          flex: { xs: "0 0 50%", sm: 0.72 },
          display: "flex",
          order: { xs: 1, sm: 2 },
          height: { xs: "50vh", sm: "100%" },
          position: { xs: "sticky", sm: "static" },
          top: 0,
          zIndex: 1,
        }}
      >
        <Box sx={{ width: "100%", height: "100%" }}>
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "100%",
            }}
            center={mapCenter}
            zoom={13}
            onLoad={(map) => {
              mapRef.current = map;
              if (bounds) {
                map.fitBounds(bounds);
              }
            }}
          >
            {!directions && (
              <>
                {startPoint?.location && (
                  <Marker position={startPoint.location} label="S" />
                )}
                {endPoint?.location && (
                  <Marker position={endPoint.location} label="E" />
                )}
                {stops.map(
                  (stop, index) =>
                    stop.location && (
                      <Marker
                        key={index}
                        position={stop.location}
                        label={(index + 1).toString()}
                      />
                    )
                )}
              </>
            )}
            {directions && <DirectionsRenderer directions={directions} />}
          </GoogleMap>
        </Box>
      </Box>

      {/* Controls Container - Scrollable on mobile */}
      <Box
        sx={{
          backgroundColor: "#fff",
          overflowY: "auto",
          width: { xs: "95%", sm: "320px" },
          flex: { xs: "1 1 50%", sm: "none" },
          order: { xs: 2, sm: 1 },
          height: { xs: "50vh", sm: "100vh" },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
            <b>Route Optimization</b>
          </Typography>

          <Box sx={{ mb: 2 }}>
            <LocationAutocomplete
              onSelect={handlePlaceSelect}
              placeholder="Enter start location"
              type="start"
              value={startPoint}
            />
          </Box>

          <Box sx={{ mb: 1 }}>
            <LocationAutocomplete
              onSelect={handlePlaceSelect}
              placeholder="Enter end location"
              type="end"
              value={endPoint}
            />
          </Box>

          <Divider sx={{ mt: 2, mb: 1 }} />

          <Typography gutterBottom sx={{ fontSize: "16px" }}>
            Stops
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              mb: 3,
            }}
          >
            <Button
              variant="outlined"
              component="label"
              startIcon={<UploadFileIcon />}
              sx={{ mr: 1, fontSize: "14px", pr: 3 }}
            >
              Upload csv
              <input
                type="file"
                hidden
                accept=".csv"
                onChange={handleFileUpload}
              />
            </Button>
            <Button
              variant="outlined"
              component="label"
              startIcon={<UploadFileIcon />}
              sx={{ fontSize: "14px", pr: 3 }}
            >
              Upload txt
              <input
                type="file"
                hidden
                accept=".txt"
                onChange={handleFileUpload}
              />
            </Button>
          </Box>
          <LocationAutocomplete
            onSelect={handlePlaceSelect}
            placeholder="Add a stop"
            type="stop"
            value={null}
          />
          <Paper
            elevation={0}
            sx={{
              maxHeight: 200,
              minHeight: 150,
              overflow: "auto",
              mb: 2,
              mt: 2,
              flex: 1,
              border: "1px solid #ccc",
            }}
          >
            <List>
              {(directions ? dirStops : stops).map((stop, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteStop(index)}
                      disabled={!!directions} // Disable delete when directions are shown
                    >
                      <DeleteIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={`${index + 1}. ${stop.address}`}
                    primaryTypographyProps={{ style: { fontSize: "12px" } }}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 3 }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                type="number"
                size="small"
                sx={{
                  width: "50%",
                  "& .MuiInputBase-input": {
                    fontSize: "0.9rem",
                  },
                }}
                label="Number of Visits"
                value={nVisits}
                onChange={handleNVisitsChange}
                onBlur={() => {
                  if (nVisits === "" || parseInt(nVisits, 10) < 0) {
                    setNVisits("0");
                  } else if (parseInt(nVisits, 10) > stops.length) {
                    setNVisits(stops.length.toString());
                  }
                }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                fullWidth
                helperText={`Max: ${stops.length}`}
              />
              <TextField
                label="Maximum Distance (km)"
                size="small"
                sx={{
                  width: "50%",
                  "& .MuiInputBase-input": {
                    fontSize: "0.9rem",
                  },
                }}
                type="number"
                value={maximumDistance}
                onChange={handleMaximumDistanceChange}
                inputProps={{ min: 0, step: 0.1 }}
                fullWidth
              />
            </Box>
            <Button
              variant="contained"
              onClick={() => calculateRoute()}
              startIcon={<RouteIcon />}
              disabled={
                isCalculating || !startPoint || !endPoint || stops.length === 0
              }
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
                fontSize: "14px",
              }}
            >
              {isCalculating ? (
                <CircularProgress size={24} />
              ) : (
                "Calculate Route"
              )}
            </Button>

            {calculatedRouteInfo && (
              <Box
                ref={calculatedRouteRef}
                sx={{
                  p: 2,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <Typography variant="h6">Calculated Route Info</Typography>
                <Typography variant="body1" color="text.primary">
                  {calculatedRouteInfo}
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              onClick={optimizeRoute}
              startIcon={<OptimizeIcon />}
              disabled={
                isOptimizing || !startPoint || !endPoint || stops.length === 0
              }
              sx={{
                backgroundColor: "#2e7d32",
                "&:hover": {
                  backgroundColor: "#1b5e20",
                },
                fontSize: "14px",
              }}
            >
              {isOptimizing ? <CircularProgress size={24} /> : "Optimize Route"}
            </Button>

            {optimizedRouteInfo && (
              <Box
                ref={optimizedRouteRef}
                sx={{
                  p: 2,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <Typography variant="h6">Optimized Route Info</Typography>
                <Typography variant="body1" color="text.primary">
                  {optimizedRouteInfo}
                </Typography>
              </Box>
            )}
            {optimizedPlan && (
              <Button
                variant="outlined"
                onClick={downloadOptimizedPlan}
                startIcon={<DownloadIcon />}
                sx={{ fontSize: "14px" }}
              >
                Download Optimized Plan
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={toggleChatbot}
              startIcon={<ChatIcon />}
              sx={{ fontSize: "14px" }}
              disabled={optimizedPlan === null}
            >
              {isChatbotOpen ? "Close Assistant" : "Open Assistant"}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Chatbot Overlay */}
      {isChatbotOpen && (
        <Box
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            width: { xs: "90%", sm: 350 },
            height: { xs: "80%", sm: 500 },
            maxHeight: { xs: "calc(50vh - 40px)", sm: "500px" },
            zIndex: 1001,
            boxShadow: 3,
            borderRadius: 2,
            overflow: "hidden",
            bgcolor: "background.paper",
          }}
        >
          <Chatbot
            onClose={toggleChatbot}
            startPoint={startPoint}
            endPoint={endPoint}
            stops={stops}
            n_visits={nVisits}
            maximum_distance={maximumDistance}
            calculateRoute={calculateRoute}
            distanceMatrix={distanceMatrix}
          />
        </Box>
      )}
    </Box>
  );
};

export default RouteOpt;
