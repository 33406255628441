import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import DiscordIcon from "../assets/discord_icon.svg"; // Importing the SVG file
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        textAlign: "center",
        borderTop: 1,
        borderColor: "grey.200",
        backgroundColor: "background.paper",
      }}
    >
      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        <Button component={Link} to="/terms-of-service" color="inherit">
          Terms of Service
        </Button>{" "}
        |{" "}
        <Button component={Link} to="/privacy-policy" color="inherit">
          Privacy Policy
        </Button>{" "}
        |{" "}
        <Button
          component="a"
          href="mailto:support@optexity.com"
          color="inherit"
        >
          support@optexity.com
        </Button>
      </Typography>
      <Box sx={{ mt: 2 }}>
        <IconButton
          component="a"
          href="https://discord.gg/V5PNPuRz"
          target="_blank"
          rel="noopener"
          color="inherit"
          aria-label="Discord"
          sx={{ mx: 1 }}
        >
          <img
            src={DiscordIcon}
            alt="Discord"
            style={{ width: 24, height: 24 }}
          />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.linkedin.com/company/optexity"
          target="_blank"
          rel="noopener"
          color="inherit"
          aria-label="LinkedIn"
          sx={{ mx: 1 }}
        >
          <LinkedInIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.youtube.com/@Optexity"
          target="_blank"
          rel="noopener"
          color="inherit"
          aria-label="Youtube"
          sx={{ mx: 1 }}
        >
          <YouTubeIcon />
        </IconButton>
        <IconButton
          component="a"
          href="https://x.com/Optexity"
          target="_blank"
          rel="noopener"
          color="inherit"
          aria-label="Twitter"
          sx={{ mx: 1 }}
        >
          <TwitterIcon sx={{ height: 22 }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;
