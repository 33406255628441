import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { sendEmail } from "../utils/sendEmail";
import ConfirmationModal from "./shared/ConfirmationModal";

const ContactModal = ({ open, onClose }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendEmail(formData);
      setShowConfirmationModal(true);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.name !== "message") {
      event.preventDefault();
    }
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: { xs: 2, sm: 4 },
            textAlign: "center",
            borderRadius: 2,
            maxWidth: { xs: "90%", sm: 500 },
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              onClick={onClose}
              sx={{ position: "absolute", top: 4, right: 4 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h4" gutterBottom>
            Contact Us
          </Typography>
          <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            <TextField
              label="Email"
              name="email"
              type="email"
              fullWidth
              margin="normal"
              required
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              label="Name"
              name="name"
              fullWidth
              margin="normal"
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              label="Phone"
              name="phone"
              fullWidth
              margin="normal"
              value={formData.phone}
              onChange={handleChange}
            />
            <TextField
              label="Message"
              name="message"
              fullWidth
              margin="normal"
              value={formData.message}
              onChange={handleChange}
              multiline
              rows={4}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, minWidth: 150 }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      <ConfirmationModal
        open={showConfirmationModal}
        message="Thank you for contacting us. We will get back to you soon."
        onClose={handleCloseConfirmationModal}
      />
    </>
  );
};

export default ContactModal;
