import React, { useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import { sendEmail } from "../utils/sendEmail";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendEmail(formData);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          p: { xs: 2, sm: 4 },
          textAlign: "center",
          borderRadius: 2,
          maxWidth: { xs: "90%", sm: 500 },
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}></Box>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            name="email"
            type="email"
            fullWidth
            margin="normal"
            required
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            label="Name"
            name="name"
            fullWidth
            margin="normal"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            label="Phone"
            name="phone"
            fullWidth
            margin="normal"
            value={formData.phone}
            onChange={handleChange}
          />
          <TextField
            label="Message"
            name="message"
            fullWidth
            margin="normal"
            value={formData.message}
            onChange={handleChange}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2, minWidth: 150 }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </>
  );
};

export default ContactUs;
