import React from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, Box, useMediaQuery } from "@mui/material";
import { blogsData } from "./Blogs";
import BlogContent from "./BlogContent";
import theme from "../theme";

const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogsData.find((blog) => blog.id.toString() === id);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!blog) {
    return <Typography variant="h5">Blog not found</Typography>;
  }

  return (
    <Container>
      <Box my={5}>
        <Typography
          variant="h3"
          fontWeight={600}
          component="h1"
          gutterBottom
          paddingX={isMobile ? 0 : 15}
        >
          {blog.title}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          paddingX={isMobile ? 0 : 15}
        >
          By {blog.author} | {blog.date}
        </Typography>
        <Box my={3} paddingX={isMobile ? 0 : 15}>
          <img
            src={blog.image}
            alt={blog.title}
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Typography variant="body1" paragraph>
          <BlogContent />
        </Typography>
      </Box>
    </Container>
  );
};

export default BlogDetail;
