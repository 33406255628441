import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Optexity_logo from "../assets/Optexity_logo_small.svg";
import { UserContext } from "../UserContext";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "https://codescore.net";

const Chatbot = ({
  onClose,
  startPoint,
  endPoint,
  stops,
  n_visits,
  maximum_distance,
  calculateRoute,
  distanceMatrix,
}) => {
  const [messages, setMessages] = useState([
    {
      text: "Hello! How can I assist you with route optimization today?",
      sender: "bot",
    },
  ]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (input.trim() === "") return;

    setMessages((prev) => [...prev, { text: input, sender: "user" }]);
    setInput("");
    setIsLoading(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/chatbot`, {
        query: input,
        routeInfo: {
          start_point: startPoint,
          end_point: endPoint,
          stops: stops,
          n_visits: n_visits,
          maximum_distance: maximum_distance,
          distance_matrix: distanceMatrix,
        },
      });

      if (response.data.dirStops) {
        // If dirStops are returned, call calculateRoute
        await calculateRoute(response.data.dirStops, true);
        setMessages((prev) => [
          ...prev,
          {
            text:
              response.data.response +
              " I've updated the route based on the optimization. You can see the new route on the map.",
            sender: "bot",
          },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          { text: response.data.response, sender: "bot" },
        ]);
      }
    } catch (error) {
      console.error("Error communicating with chatbot API:", error);
      setMessages((prev) => [
        ...prev,
        {
          text: "I'm sorry, I'm having trouble processing your request right now.",
          sender: "bot",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.paper",
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          py: 1,
          px: 2,
          borderBottom: "1px solid #ccc",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "primary.main",
          color: "primary.contrastText",
        }}
      >
        <Typography variant="h6">Route Optimization Assistant</Typography>
        <IconButton
          onClick={onClose}
          size="small"
          sx={{ color: "primary.contrastText" }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{
          flex: 1,
          overflow: "auto",
        }}
      >
        <List>
          {messages.map((message, index) => (
            <ListItem
              key={index}
              sx={{
                justifyContent:
                  message.sender === "user" ? "flex-end" : "flex-start",
                alignItems: "flex-start",
              }}
            >
              {message.sender === "bot" && (
                <Avatar
                  src={Optexity_logo}
                  alt="Optexity Logo"
                  sx={{ width: 32, height: 32, mr: 1 }}
                />
              )}
              <Paper
                elevation={2}
                sx={{
                  p: 1,
                  maxWidth: "75%",
                  minWidth: "15%",
                  bgcolor: message.sender === "user" ? "primary.main" : "white",
                }}
              >
                <ListItemText
                  primary={message.text}
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontSize: "0.9rem",
                      color: message.sender === "user" ? "white" : "black",
                    },
                  }}
                />
              </Paper>
              {message.sender === "user" && (
                <Avatar
                  alt={user?.displayName || "User"}
                  src={user?.photoURL || "/default-avatar.jpg"}
                  sx={{ width: 32, height: 32, ml: 1 }}
                />
              )}
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </Box>
      <Box
        sx={{
          p: 2,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Type a message..."
          value={input}
          sx={{
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
            },
          }}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && !e.shiftKey && handleSend()}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSend} disabled={isLoading}>
                <SendIcon color="primary" />
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Chatbot;
