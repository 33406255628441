import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const ConfirmationModal = ({ open, message, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: { xs: 2, sm: 4 },
          textAlign: "center",
          borderRadius: 2,
          maxWidth: { xs: "90%", sm: 500 },
          width: "100%",
        }}
      >
        <Typography variant="h6" gutterBottom>
          {message}
        </Typography>
        <Button onClick={onClose} variant="contained" sx={{ mt: 2 }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
